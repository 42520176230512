.admin-meta-data {
  padding: 20px;
}

.meta-data-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.admin-tab-container {
  width: 100%;
}

.admin-tab-container > button {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.meta-tab-edit-delete {
  float: right;
}

@media (max-width: 1200px) {
  .meta-data-layout {
    grid-template-columns: 1fr; /* Stack elements vertically on smaller screens */
  }
}
