.custom-quote-proposal-container {
  background-color: '#f0f2f5';
  .custom-card-header {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .custom-readonly-select {
    display: flex;
    align-items: center;
    flex: 1;
    height: 32px; /* Match dropdown height */
    padding: 4px 11px; /* Same padding as Select */
    border: 1px solid #d9d9d9; /* Match border color */
    border-radius: 6px; /* Rounded corners like Select */
    background-color: #ffffff; /* Match dropdown background */
    cursor: default;
    color: rgba(0, 0, 0, 0.85); /* Match text color */
    font-size: 14px;
    width: fit-content;
  }


  .entry-type {
    border: 1px solid black;
    border-radius: 2px;
    padding: 10px;
  }

  .ledger-proposal-container {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 16px;
    margin-top: 100px;
  }

  .custom-add-card-button {
    margin-bottom: 20px;
    color: white;
    border-radius: 5px;
  }

  .custom-dropdown-row {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }

  .custom-select,
  .custom-input {
    width: 250px;
  }

  .custom-card-container {
    margin-bottom: 20px;
    padding: 20px;

    .custom-card {
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;

      .custom-field-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        span {
          font-weight: 500;
        }

        .custom-input,
        .custom-switch {
          width: 250px;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .custom-quote-proposal-container {
    .ledger-proposal-container {
      grid-template-columns: 1fr;
    }

    .custom-card-container {
      .custom-card {
        .custom-field-row {
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }

}
