.franchisee-section {
    width: 1000px;
    min-height: 100vh;
    padding: 40px 0;
    position: relative;
    margin: 0 auto;

    &-flex-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 20px;
        gap: 20px;

        .franchisee-report {
            width: 580px;
            margin-right: 20px;
        }

        .franchisee-upload-coi {
            width: 400px;

            .card {
                box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.1);
                background-color: #fff;
                width: 100%;

                .card-header {
                    padding: 20px;
                    margin-bottom: -15px;

                    &-wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    h2 {
                        font-size: 24px;
                        color: #000;
                        margin-bottom: 0;
                    }
                }

                .card-content {
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    >p {
                        font-size: 14px;
                    }

                    .section-upload {
                        display: block;

                        &-title {
                            font-size: 18px;
                        }

                        &-main {
                            width: 100%;
                            height: 180px;
                            box-sizing: border-box;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            border: 2px dashed #797978;
                            border-radius: 10px;
                            position: relative;
                            padding: 30px;

                            p {
                                margin: 20px auto;
                                color: #242f57;
                                font-size: 14px;
                                text-align: center;
                            }

                            .btn-add-file {
                                width: 90px;
                                height: 40px;
                                border: 2px solid #5a33ff;
                                color: #5a33ff;
                            }

                            input {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                opacity: 0;
                            }

                            .file-item {
                                display: flex;
                                justify-content: space-between;
                                background-color: rgba(61, 112, 178, 0.1);
                                align-items: center;

                                span {
                                    margin-left: 10px;

                                    svg {
                                        fill: #5a33ff;
                                    }
                                }
                            }
                        }

                        &-success {
                            display: flex;
                            justify-content: center;
                            min-height: 70px;
                            align-items: center;
                            font-size: 30px;
                            flex-direction: column;

                            .icon-tick {
                                font-size: 30px;
                                margin-right: 10px;

                                svg {
                                    fill: #028701;
                                }
                            }

                            &_desc {
                                font-size: 13px;
                                text-align: center;
                            }
                        }

                        &-error {
                            display: flex;
                            justify-content: center;
                            min-height: 70px;
                            align-items: center;
                            flex-direction: column;

                            &-wrapper {
                                font-size: 30px;

                                .icon-error {
                                    font-size: 30px;
                                    margin-right: 10px;

                                    svg {
                                        fill: #aa0000;
                                    }
                                }
                            }

                            p {
                                font-size: 13px;
                                text-align: center;
                            }
                        }
                    }
                    @media (max-width: 768px) {
                    margin-top: -20px;
                    }
                    @media (max-width: 992px) {
                    margin-top: -20px;
                    }
                }
            }
        }
    }

    @media (max-width: 992px) {
        width: 90%;

        &-flex-container {
            flex-direction: column;
            align-items: stretch;

            .franchisee-report,
            .franchisee-upload-coi {
                width: 100%;
                margin-right: 0;
            }
        }

        &-franchisee-header-container {
            margin-bottom: 0
        }
    }

    @media (max-width: 768px) {
        width: 95%;

        &-flex-container {
            flex-direction: column;
            align-items: stretch;

            .franchisee-report,
            .franchisee-upload-coi {
                width: 100%;
                margin-right: 0;
                margin-top: 20px;
            }
        }
    }
}

.franchisee-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border: 1px solid #eeeeee;
    box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.1);
    padding: 30px;

    >* {
        text-align: center;
    }

    img {
        height: 50px;
        width: auto;
    }

    h3 {
        margin: 0;
        font-weight: normal;
    }

    @media (max-width: 768px) {
        margin-bottom: 0px;
    }

    @media (max-width: 992px) {
        margin-bottom: 0px;
    }
}

.center-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.franchisee-info-box {
    text-align: center;
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px rgba(28, 41, 90, 0.1);
    padding: 10px;
    margin: 0 0 20px;

    word-wrap: break-word;
    overflow-wrap: break-word;

    @media (max-width: 992px) {
    }

    @media (max-width: 768px) {
    }
}
