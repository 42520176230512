.exem {
  padding: 10px;
  &_header {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    margin-bottom: 14px;

    &-action {
      display: flex;
      gap: 10px;
    }
  }

  &_content {
    position: relative;

    &-item {
      margin-bottom: 14px;

      &-wraper {
        border: 1px solid rgba(5, 5, 5, 0.06);
        border-radius: 8px;
        padding: 10px;

        &-header {
          margin: 0px 0px 10px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &-action {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }

        &-item {
          display: flex;
          gap: 10px;
          margin-top: 10px;
        }

        &-con {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
    }

    &-mask {
      position: absolute;
      top: 0px;
      right: 0px;
      left: 0px;
      bottom: 0px;
    }
  }
}
